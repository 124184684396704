<nb-card class="small-header ag-grid-card" style="height: 100%;" [nbSpinner]="loading" [ngStyle]="{width: width, height: height}">
  <nb-card-header>
    <ngx-card-header [size]="size" [icon]="favicon" [title]="(title ? title : 'Danh sách') + ' - ' + (selectedIds.length ?  ('Đã chọn '+selectedIds.length + '/') : '') + infiniteInitialRowCount" [controls]="actionButtonList"></ngx-card-header>
  </nb-card-header>

  <nb-card-body>
    <ag-grid-angular 
    #agGrid 
    style="width: 100%; height: 100%;" 
    id="myGrid" 
    class="{{themeName}}" 
    [modules]="modules" 
    [columnDefs]="columnDefs" 
    [debug]="true" [enableCellTextSelection]="enableCellTextSelection"
    [defaultColDef]="defaultColDef" 
    [rowSelection]="rowSelection" 
    [rowModelType]="rowModelType" 
    [paginationPageSize]="paginationPageSize" 
    [cacheOverflowSize]="cacheOverflowSize" 
    [maxConcurrentDatasourceRequests]="maxConcurrentDatasourceRequests" 
    [infiniteInitialRowCount]="infiniteInitialRowCount" 
    [maxBlocksInCache]="maxBlocksInCache" 
    [cacheBlockSize]="cacheBlockSize" 
    [getRowNodeId]="getRowNodeId" 
    [components]="components" 
    [rowData]="rowData" 
    (gridReady)="onGridReady($event)" 
    (selectionChanged)="onSelectionChanged($event)" 
    [multiSortKey]="multiSortKey" 
    [rowDragManaged]="rowDragManaged" 
    [getRowHeight]="getRowHeight" 
    [rowHeight]="rowHeight" 
    [rowHeight]="rowHeight" 
    (columnResized)="onColumnResized()" 
    (rowSelected)="onRowSelected()" 
    [pagination]="pagination"
    [rowMultiSelectWithClick]="rowMultiSelectWithClick"
    [suppressRowClickSelection]="suppressRowClickSelection"
    >
    </ag-grid-angular>
  </nb-card-body>
</nb-card>