<form [formGroup]="form" (ngSubmit)="onSubmit()" (keydown.shift.enter)="$event.preventDefault()" (keydown.enter)="$event.preventDefault()" class="dialog-wrap popup" style="width: 90%; margin: 0 auto;">
  <div formArrayName="array">
    <div *ngFor="let formItem of array.controls; let i = index">
      <nb-card class="small-header popup" [nbSpinner]="loading">
        <nb-card-header>
          <ngx-card-header [option]="{controlOption: {formIndex: i, form: formItem}}" [size]="size" [icon]="favicon" [title]="title ? title : ('WP Sync Profile' | translate:{definition: '', action: cms.translate.instant(isEditMode ? 'Common.update' : 'Common.create')} | headtitlecase)" [controls]="actionButtonList"></ngx-card-header>
        </nb-card-header>
        <nb-card-body style="overflow: hidden;">
          <div [formGroup]="formItem">
            <div class="row">
              <div class="col-sm-12">
                <ngx-form-group [formGroup]="formItem" name="Sites" label="Sites" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <ngx-select2 formControlName="Sites" [select2Option]="select2OptionForSites" [data]="groupList">
                  </ngx-select2>
                </ngx-form-group>
              </div>
              <!-- <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ProductGroups" label="Nhóm sản phẩm" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <nb-checkbox class="form-group-icon-control" formControlName="IsNotInGroups" nbTooltip="Ngoài trừ các nhóm này"></nb-checkbox>
                  <ngx-select2 class="form-group-input-control" formControlName="ProductGroups" [select2Option]="select2OptionForGroups" [data]="groupList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="ProductCategories" label="Danh mục sản phẩm" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <nb-checkbox class="form-group-icon-control" formControlName="IsNotInCategories" nbTooltip="Ngoại trừ các danh mục này"></nb-checkbox>
                  <ngx-select2 class="form-group-input-control" formControlName="ProductCategories" [select2Option]="select2OptionForCategories" [data]="categoryList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-sm-6">
                <ngx-form-group [formGroup]="formItem" name="Products" label="Sản phẩm" [array]="array" [index]="i" [allowCopy]="true" [required]="false" [touchedValidate]="false">
                  <nb-checkbox class="form-group-icon-control" formControlName="IsNotInProducts" nbTooltip="Ngoại trừ các sản phẩm này"></nb-checkbox>
                  <ngx-select2 class="form-group-input-control" formControlName="Products" [select2Option]="select2OptionForProducts" [data]="categoryList"></ngx-select2>
                </ngx-form-group>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <button type="button" nbButton status="primary" hero size="small" (click)="loadProducts() && false">
                    <nb-icon pack="eva" icon="download-outline"></nb-icon>Nạp danh sách sản phẩm theo cấu hình
                  </button>
                </div>
              </div> -->
            </div>
            <div class="row">
              <div class="col-md-12">
                <label class="label">{{'Danh sách task' | translate | headtitlecase}}</label>
                <table style="width: 100%;" class="task-table">
                  <thead>
                    <th>STT</th>
                    <th>Task</th>
                    <th>Site</th>
                    <th>Số sản phẩm</th>
                    <!-- <th>Số biến thể</th> -->
                    <th>Tiến trình</th>
                    <th>Bắt đầu</th>
                    <th>Kết thúc</th>
                    <th>Tổng thời gian</th>
                    <th>Người thực thi</th>
                    <th>Trạng thái</th>
                    <th>Trạng thái lệnh</th>
                    <th>Thông báo</th>
                    <th style="text-align: right;">Hành động</th>
                  </thead>
                  <tr class="task" *ngFor="let task of syncTasks; let i=index" (click)="activeTask(task.id)" [ngClass]="{'active': task.id == activeTaskId}">
                    <td>{{i+1}}</td>
                    <td>{{task.Code}}</td>
                    <td>{{task.SiteName}}</td>
                    <td>{{task.NumOfProducts | number:'1.0-0'}}</td>
                    <!-- <td>{{task.Total | number:'1.0-0'}}</td> -->
                    <td>{{task.Loaded/task.Total*100 | number:'1.0-0'}}%</td>
                    <td>{{task.StartTime | date:'short'}}</td>
                    <td>{{task.EndTime | date:'short'}}</td>
                    <td>{{task.Duration}}</td>
                    <td>{{task.ExecutorName}}</td>
                    <td>{{task.State}}</td>
                    <td>{{task.Status}}</td>
                    <td>{{task.Message}}</td>
                    <td style="text-align: right;">
                      <button [disabled]="task.State === 'STOP' || task.State === 'SUCCESS'" nbButton status="danger" hero size="small" (click)="changeState(task) && false">STOP</button>
                    </td>
                  </tr>
                </table>
              </div>
            </div>
            <label class="label">{{'Danh sách sản phẩm sẽ được đồng bộ' | translate | headtitlecase}}</label>
            <ngx-ag-dynamic-list #agSyncTaskDetailList [columnDefs]="columnDefs" [apiPath]="syncTaskDetailsApiPath" [prepareApiParams]="prepareSyncTaskDetailsParams" [idKey]="'Id'" style="display: block; height: 500px"></ngx-ag-dynamic-list>
          </div>
        </nb-card-body>
        <nb-card-footer>
          <div class="buttons-row" style="text-align: right;">
            <!-- <button nbButton status="primary" [disabled]="!form.valid || loading" style="float: right;" hero (click)="saveAndClose()">{{'Common.saveAndClose' | translate | headtitlecase}}</button> -->
            <!-- <button nbButton status="success" [disabled]="!form.valid || loading" style="float: right;" hero>{{'Common.save' | translate | headtitlecase}}</button> -->
            <button nbButton status="danger" [disabled]="!form.valid || loading" style="float: right;" hero (click)="sync() && false">
              <nb-icon pack="eva" icon="cloud-upload-outline"></nb-icon>{{'Common.sync' | translate | headtitlecase}}
            </button>
          </div>
        </nb-card-footer>
      </nb-card>
    </div>
  </div>
</form>